import PieChart from '../../common/charts/PieChart';
import { graphSettings } from '../common/graphSettings';
import NoGraphPlaceholder from '../common/NoGraphPlaceholder';

export default function LanguageGraph({ graphInfo }) {
  // If no data display no data placeholder
  if (graphInfo.length === 0) {
    return <NoGraphPlaceholder />;
  }

  return (
    <PieChart
      data={graphInfo}
      margin={{ ...graphSettings.margin.LanguageGraph }}
    />
  );
}
