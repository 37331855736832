export default function NoGraphIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="117"
      height="116"
      viewBox="0 0 117 116"
      fill="none"
    >
      <rect width="116" height="116" rx="58" fill="#f3f4f6" />
      <path
        d="M7.51831 28.5357C7.51831 26.1386 9.46159 24.1953 11.8587 24.1953H103.41C105.807 24.1953 107.75 26.1386 107.75 28.5357V86.2875C107.75 88.6847 105.807 90.6279 103.41 90.6279H11.8587C9.46158 90.6279 7.51831 88.6847 7.51831 86.2875V28.5357Z"
        fill="#cbd5e1"
        stroke="#cbd5e1"
        strokeWidth="1.08511"
      />
      <rect
        x="12.1799"
        y="32.3555"
        width="39.6265"
        height="30.3026"
        rx="3.25532"
        fill="#f3f4f6"
      />
      <rect
        x="12.1799"
        y="64.9883"
        width="39.6265"
        height="20.9787"
        rx="3.25532"
        fill="#f3f4f6"
      />
      <rect
        x="54.1377"
        y="32.3555"
        width="48.9504"
        height="53.6123"
        rx="3.25532"
        fill="#f3f4f6"
      />
      <ellipse
        cx="12.887"
        cy="28.9195"
        rx="1.04061"
        ry="1.04061"
        fill="#64748b"
      />
      <ellipse
        cx="17.0496"
        cy="28.9195"
        rx="1.04061"
        ry="1.04061"
        fill="#64748b"
      />
      <ellipse
        cx="21.213"
        cy="28.9195"
        rx="1.04061"
        ry="1.04061"
        fill="#64748b"
      />
      <path
        d="M69.9113 52.7496C69.9113 54.0592 68.8497 55.1208 67.5402 55.1208C66.2306 55.1208 65.169 54.0592 65.169 52.7496C65.169 51.4401 66.2306 50.3785 67.5402 50.3785C68.8497 50.3785 69.9113 51.4401 69.9113 52.7496Z"
        fill="#64748b"
        stroke="#64748b"
        strokeWidth="1.08511"
      />
      <path
        d="M92.0554 52.7496C92.0554 54.0592 90.9938 55.1208 89.6842 55.1208C88.3747 55.1208 87.3131 54.0592 87.3131 52.7496C87.3131 51.4401 88.3747 50.3785 89.6842 50.3785C90.9938 50.3785 92.0554 51.4401 92.0554 52.7496Z"
        fill="#64748b"
        stroke="#64748b"
        strokeWidth="1.08511"
      />
      <path
        d="M73.9502 63.8168L78.6121 60.3203L83.2741 63.8168"
        stroke="#64748b"
        strokeWidth="2.17021"
      />
      <path
        opacity="0.5"
        d="M22.6687 73.1499L25.2396 72.1786L27.8105 73.1499L30.8957 71.9844L33.9808 73.1499L37.0659 71.9844L40.151 73.1499"
        stroke="#64748b"
        strokeWidth="2.17021"
      />
      <path
        d="M22.6685 78.9741L25.2394 78.0028L27.8103 78.9741L30.8954 77.8086L33.9805 78.9741L37.0656 77.8086L40.1507 78.9741"
        stroke="#64748b"
        strokeWidth="2.17021"
      />
      <path
        d="M30.783 50.2919V50.4276H30.9186H32.5758H32.7114V50.2919V50.183C32.7191 49.7473 32.7684 49.3885 32.8561 49.1034C32.9432 48.8203 33.0802 48.5776 33.2666 48.3732C33.461 48.1642 33.717 47.9594 34.0372 47.7596C34.3943 47.5452 34.7018 47.298 34.9585 47.0173C35.2209 46.7346 35.4231 46.4132 35.5644 46.0538L35.5646 46.0533C35.7068 45.6877 35.7766 45.2805 35.7766 44.8337C35.7766 44.1821 35.6225 43.606 35.3098 43.1104C35.0021 42.6126 34.5691 42.2272 34.0151 41.9541C33.46 41.6766 32.8167 41.5401 32.0891 41.5401C31.4224 41.5401 30.8099 41.6672 30.2535 41.9234L30.2531 41.9236C29.6979 42.1811 29.2499 42.5623 28.9114 43.0659C28.5702 43.5697 28.3889 44.1826 28.361 44.8979L28.3556 45.0389H28.4966H30.2581H30.385L30.3934 44.9122C30.4193 44.5197 30.5167 44.211 30.677 43.9776L30.6775 43.9768C30.8422 43.7333 31.0475 43.5573 31.2937 43.4447C31.5464 43.3292 31.8111 43.2716 32.0891 43.2716C32.3976 43.2716 32.6732 43.3367 32.9186 43.4648L32.9186 43.4648L32.9207 43.4659C33.1697 43.5903 33.3675 43.7671 33.5164 43.9976C33.6625 44.2236 33.7381 44.4946 33.7381 44.8163C33.7381 45.0846 33.6874 45.3258 33.5882 45.5419C33.487 45.7625 33.3498 45.9574 33.1762 46.1274C32.9983 46.3016 32.7977 46.4597 32.574 46.6014C32.2067 46.8227 31.8905 47.069 31.6267 47.3409C31.3545 47.6212 31.1482 47.9843 31.0041 48.4245L31.0041 48.4245L31.0037 48.4256C30.8627 48.8687 30.7908 49.4556 30.783 50.1804H30.783V50.1818V50.2919ZM30.8806 53.5747L30.8815 53.5755C31.1345 53.8243 31.4409 53.9505 31.7935 53.9505C32.0294 53.9505 32.2468 53.8923 32.4428 53.7747C32.6356 53.6591 32.7905 53.5041 32.9063 53.3114C33.0275 53.1121 33.088 52.8926 33.088 52.656C33.088 52.3021 32.957 51.997 32.6998 51.7481C32.4475 51.4961 32.1432 51.3673 31.7935 51.3673C31.4403 51.3673 31.1338 51.4959 30.881 51.7485C30.6277 51.9977 30.499 52.3027 30.499 52.656C30.499 53.0128 30.6274 53.3215 30.8806 53.5747Z"
        fill="#64748b"
        stroke="#64748b"
        strokeWidth="0.271277"
      />
      <rect
        opacity="0.25"
        x="58.7993"
        y="74.3125"
        width="6.99291"
        height="2.33097"
        fill="#64748b"
      />
      <rect
        opacity="0.25"
        x="68.1233"
        y="74.3125"
        width="6.99291"
        height="2.33097"
        fill="#64748b"
      />
      <rect
        x="77.447"
        y="74.3125"
        width="20.9787"
        height="2.33097"
        fill="#64748b"
      />
      <rect
        x="63.4609"
        y="78.9688"
        width="13.9858"
        height="2.33097"
        fill="#64748b"
      />
      <rect
        opacity="0.25"
        x="79.7778"
        y="78.9688"
        width="13.9858"
        height="2.33097"
        fill="#64748b"
      />
      <rect
        opacity="0.25"
        x="42.4827"
        y="56.8242"
        width="2.46809"
        height="2.33097"
        fill="#64748b"
      />
      <rect
        opacity="0.5"
        x="45.7734"
        y="56.8242"
        width="2.46809"
        height="2.33097"
        fill="#64748b"
      />
      <rect
        opacity="0.25"
        x="45.7725"
        y="53.3438"
        width="2.46809"
        height="2.33097"
        fill="#64748b"
      />
      <path
        d="M105.26 21.1254V16.1133"
        stroke="#64748b"
        strokeWidth="1.08511"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.311 22.5645L112.891 18.9844"
        stroke="#64748b"
        strokeWidth="1.08511"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.988 26.8516L116.001 26.8516"
        stroke="#64748b"
        strokeWidth="1.08511"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
