import { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { format } from 'date-fns';
import { ActionIcon, createStyles, Stack, Tooltip } from '@mantine/core';
import { Image, Modal, Text } from '@akin/ui-lib';
import ICONS from '@akin/core-lib/icons';
import DataTable from '../../common/data-table';
import GraphCard from '../common/GraphCard';
import useSharedStyles from '../sharedStyles';
import { getInboxConversationLink } from '../../chat/utils';
import useSpace from '../../../hooks/useSpace';

const useStyles = createStyles(() => ({
  modalRow: { display: 'flex', alignItems: 'flex-start', gap: '12px' },
  modalLabel: { minWidth: '120px' },
  modalValue: { whiteSpace: 'pre-wrap', flex: 1 },
  convContainer: { display: 'flex', justifyContent: 'center' },
  convImage: {
    filter:
      'invert(80%) sepia(2%) saturate(0%) hue-rotate(137deg) brightness(86%) contrast(90%)',
  },
  center: { textAlign: 'center' },
  ellipses: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

// Helper function to format row data for modal display
function formatRowData(row = {}) {
  return [
    {
      label: t`Time`,
      value: row?.timestamp
        ? format(new Date(row?.timestamp), 'd MMM, h:mmaaa')
        : '-',
    },
    { label: t`CSAT Score`, value: row?.score },
    { label: t`Type`, value: row?.type },
    { label: t`Comment`, value: row?.comment },
    { label: t`Tags`, value: row?.tags },
    { label: t`Conversation ID`, value: row?.externalConversationId },
  ];
}

// Modal component for displaying detailed conversation information
function CommonModal({ opened, onClose, title, rowData }) {
  const { classes } = useStyles();
  const formattedData = formatRowData(rowData);

  return (
    <Modal opened={opened} onClose={onClose} title={title} size="lg">
      <Stack spacing="xs">
        {formattedData.map(({ label, value }) => (
          <div key={label} className={classes.modalRow}>
            <Text
              size="sm"
              weight={600}
              color="dimmed"
              className={classes.modalLabel}
            >
              {label}
            </Text>
            <Text size="sm" className={classes.modalValue}>
              {value}
            </Text>
          </div>
        ))}
      </Stack>
    </Modal>
  );
}

// Collection of cell renderer components for the data table
const TableCells = {
  // Base cell component for consistent text styling
  Base: ({ children }) => <Text size="xs">{children}</Text>,

  // Cell component for conversation links with icon
  Conversation: ({ value }) => {
    const { currentSpace } = useSpace();
    const { classes } = useStyles();
    const link = getInboxConversationLink(currentSpace?.space_code, value);

    return (
      <div className={classes.convContainer}>
        <Tooltip label={t`Open conversation`}>
          <ActionIcon
            variant="subtle"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(link, '_blank', 'noopener,noreferrer');
            }}
          >
            <Image
              src={ICONS.newTab}
              width={16}
              height={16}
              className={classes.convImage}
            />
          </ActionIcon>
        </Tooltip>
      </div>
    );
  },

  // Cell component for formatted timestamps
  Time: ({ value }) => (
    <TableCells.Base>
      {format(new Date(value), 'd MMM, h:mmaaa').replace(
        /(\w+)/g,
        (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
      )}
    </TableCells.Base>
  ),

  // Cell component for center-aligned content
  CenterAlign: ({ value }) => {
    const { classes } = useStyles();
    return (
      <div className={classes.center}>
        <TableCells.Base>{value}</TableCells.Base>
      </div>
    );
  },

  // Cell component for tags with text truncation
  Tags: ({ value }) => {
    const displayText = value?.length > 25 ? `${value.slice(0, 25)}...` : value;
    return <TableCells.Base>{displayText}</TableCells.Base>;
  },

  // Cell component for comments with line clamping
  Comments: ({ value }) => {
    const { classes } = useStyles();
    return (
      <TableCells.Base>
        <div className={classes.ellipses}>{value}</div>
      </TableCells.Base>
    );
  },
};

// Configuration for table columns with styling and cell renderers
const getTableColumns = () => [
  {
    id: 'timestamp',
    title: t`Time`,
    accessor: 'timestamp',
    style: { padding: 6, fontSize: 12, width: 80 },
    Cell: TableCells.Time,
    width: 20,
  },
  {
    id: 'score',
    title: t`CSAT Score`,
    accessor: 'score',
    style: { padding: 6, textAlign: 'center', fontSize: 12, width: 50 },
    Cell: TableCells.CenterAlign,
    excelStyle: { alignment: { horizontal: 'center' } },
    width: 10,
  },
  {
    id: 'type',
    title: t`Type`,
    accessor: 'type',
    style: { padding: 6, textAlign: 'center', fontSize: 12, width: 120 },
    Cell: TableCells.CenterAlign,
    excelStyle: { alignment: { horizontal: 'center' } },
    width: 20,
  },
  {
    id: 'comment',
    title: t`Comment`,
    accessor: 'comment',
    style: { padding: 6, fontSize: 12 },
    Cell: TableCells.Comments,
    width: 60,
  },
  {
    id: 'tags',
    title: t`Tags`,
    accessor: 'tags',
    style: { padding: 6, fontSize: 12, width: 130 },
    Cell: TableCells.Tags,
    width: 20,
  },
  {
    id: 'externalConversationId',
    title: t`Conversation`,
    accessor: 'externalConversationId',
    style: { padding: 6, textAlign: 'center', fontSize: 12, width: 110 },
    Cell: TableCells.Conversation,
    width: 10,
  },
];

// Main component for displaying CSAT scores in a table format
export default function CSATScoresTable({ data }) {
  const { classes } = useSharedStyles();
  const { currentSpace } = useSpace();
  const [selectedRow, setSelectedRow] = useState(null);

  // Memoize columns to prevent unnecessary recalculations
  const columns = useMemo(() => getTableColumns(), []);

  return (
    <GraphCard.Content className={classes.overflowYGraph}>
      <DataTable
        columns={columns}
        data={data}
        title={`CSAT Scores - ${currentSpace?.name || ''}`}
        options={{
          paging: true,
          pageSizeOptions: [5],
          hidePageSelect: true,
          hideTitle: true,
          cellStyle: { padding: 6 },
          headerStyle: { backgroundColor: '#f3f4f6' },
          allowExport: { excel: true, pdf: false },
        }}
        rowProps={(row) => ({
          onClick: () => setSelectedRow(row.original),
          style: { cursor: 'pointer' },
        })}
      />

      <CommonModal
        opened={!!selectedRow}
        onClose={() => setSelectedRow(null)}
        title="Conversation Details"
        rowData={selectedRow}
      />
    </GraphCard.Content>
  );
}
