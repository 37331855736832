import React from 'react';
import { Plural, t } from '@lingui/macro';
import BarGraph from '../../common/charts/BarGraph';
import Tooltip from '../common/Tooltip';
import { graphSettings } from '../common/graphSettings';

const tooltipComponent = ({ value, color }) => (
  <Tooltip color={color}>
    {value} {` `}
    <Plural value={value} one="min" other="mins" />
  </Tooltip>
);

export default function AvgResponseTimeByHourGraph({ graphInfo }) {
  return (
    <BarGraph
      data={graphInfo}
      keys={graphInfo.map((info) => info.id)}
      indexBy="id"
      tooltip={tooltipComponent}
      colors={graphSettings.monochromeColorPalette}
      xAxisTickRotation={90}
      graphProps={{
        axisLeft: {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t`in mins`,
          legendPosition: 'middle',
          legendOffset: -40,
          truncateTickAt: 0,
          format: (e) => Math.floor(e) === e && e,
        },
        margin: {
          ...graphSettings.margin.AvgResponseTimeByHourGraph,
          ...(graphInfo.length === 0 && { bottom: 10 }),
        },
      }}
    />
  );
}
