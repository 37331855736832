import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  tooltip: {
    padding: '5px 7px',
    background: '#1f2937',
    maxWidth: '170px',
    borderRadius: 2,
    color: '#fff',
    fontSize: 12,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  cell: {
    padding: '3px 5px',
  },
  box: {
    display: 'block',
    width: '12px',
    height: '12px',
    marginRight: '4px',
  },
}));

export default function Tooltip({ color, children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.tooltip}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td className={classes.cell}>
              <span
                className={classes.box}
                style={{ backgroundColor: color }}
              />
            </td>
            <td className={classes.cell}>{children}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
