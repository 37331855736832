import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import BarGraph from '../../common/charts/BarGraph';
import GraphInsights from '../GraphInsights';
import { HUMAN_ALL_LV, HUMAN_ALL } from '../constants';
import GraphCard from '../common/GraphCard';
import Tooltip from '../common/Tooltip';
import { graphSettings } from '../common/graphSettings';

const tooltipComponent = ({ id, value, color, data }) => {
  const info = data[`${id}Data`];
  return (
    <Tooltip color={color}>
      {info.label}: {value} {t`Messages`} ({info.percent}%)
    </Tooltip>
  );
};

export default function ResponseTimeDistributionGraph({ data }) {
  const [typeGraph, setTypeGraph] = useState(HUMAN_ALL.all);

  const {
    responseTimeDistribution: graphInfo,
    averageResponseTime,
    averageFirstResponseTime,
  } = useMemo(() => {
    return typeGraph === HUMAN_ALL.human
      ? data.humanResponseTime
      : data.allResponseTime;
  }, [typeGraph, data.allResponseTime, data.humanResponseTime]);

  const keys = ['responseTimeDistribution', 'firstResponseTimeDistribution'];
  const colors = graphSettings.dualColorPalette;

  return (
    <>
      <GraphCard.Header
        showOptions
        optionList={HUMAN_ALL_LV}
        onChange={setTypeGraph}
        selected={typeGraph}
      >
        <GraphInsights
          title={t`Average Response Time`}
          description={averageResponseTime || '-'}
        />
        <GraphInsights
          title={t`Average First Response Time`}
          description={averageFirstResponseTime || '-'}
        />
      </GraphCard.Header>

      <GraphCard.Content>
        <BarGraph
          data={graphInfo}
          keys={keys}
          indexBy="id"
          tooltip={tooltipComponent}
          colors={colors}
          showLegends
          groupMode="grouped"
          graphProps={{
            colors: graphSettings.dualColorPalette,
            axisLeft: {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t`Total conversations`,
              legendPosition: 'middle',
              legendOffset: -40,
              truncateTickAt: 0,
              format: (e) => Math.floor(e) === e && e,
            },
            legends: [
              {
                dataFrom: 'keys',
                data: keys.map((id, index) => ({
                  color: colors[index],
                  id,
                  label:
                    id === 'responseTimeDistribution'
                      ? t`Response Time`
                      : t`First Response Time`,
                })),
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 40,
                translateY: -30,
                itemWidth: 200,
                itemHeight: 20,
                itemsSpacing: 4,
                symbolSize: 20,
                symbolShape: 'circle',
                itemDirection: 'left-to-right',
                itemTextColor: '#777',
              },
            ],
            margin: {
              ...graphSettings.margin.ResponseTimeDistributionGraph,
              ...(graphInfo.length === 0 && { bottom: 10 }),
            },
          }}
        />
      </GraphCard.Content>
    </>
  );
}
