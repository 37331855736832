import { useMemo } from 'react';
import { t } from '@lingui/macro';

import {
  AI_CHANNELS_CONFIG,
  AI_SERVICES_CHANNELS,
  CHANNEL_MODE,
} from '../ai-service-channels/constants';

import GraphCard from './common/GraphCard';
import GraphInsights from './GraphInsights';
import ResponsesGraph from './graphs/group/Responses';
import { getGroupGraphData } from './group-helpers';
import AutomationPercentGraph from './graphs/group/AutomationPercent';
import AverageSatisfactionScoreGraph from './graphs/group/AverageSatisfactionScore';
import ProblemSolvedGraph from './graphs/group/ProblemSolved';
import BookingEngineClickGraph from './graphs/group/BookingEngineClick';
import BookingEngineValueGraph from './graphs/group/BookingEngineValue';
import useSpaceSettings from '../../hooks/useSpaceSettings';
import useSharedStyles from './sharedStyles';
import CountGraph from './graphs/Count';

/**
 * GroupGraphs Component
 * Renders a collection of graphs displaying various metrics for group-level analytics
 * @param {string} selectedChannel - The currently selected communication channel
 * @param {string} selectedPeriod - The selected time period for the data
 * @param {Object} reportData - The data to be displayed in the graphs
 */
export default function GroupGraphs({
  selectedChannel,
  selectedPeriod,
  reportData,
}) {
  const { classes } = useSharedStyles();
  const {
    spaceSettings: { subSpaces = [] },
  } = useSpaceSettings();

  /**
   * Memoized calculation of graph data for all metrics
   * Processes raw report data into formatted data for each graph type
   */
  const {
    countGraphData,
    responsesGraphData,
    automationPercentGraphData,
    avgSatisfactionScoreGraphData,
    problemSolvedPercentGraphData,
    bookingEngineClickGraphData,
    bookingEngineValueGraphData,
  } = useMemo(
    () =>
      getGroupGraphData({
        period: selectedPeriod,
        data: reportData,
        subSpaces,
      }),
    [selectedPeriod, reportData, subSpaces]
  );

  return (
    <>
      {/* Count Graph */}
      <GraphCard>
        <div className={classes.overflowGraph}>
          <CountGraph data={countGraphData} />
        </div>
      </GraphCard>

      {/* Messages by Property Graph */}
      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Messages by Property`} />
        </GraphCard.Header>
        <GraphCard.Content
          className={responsesGraphData.length === 0 && classes.graphContainer}
        >
          <ResponsesGraph data={responsesGraphData} />
        </GraphCard.Content>
      </GraphCard>

      {/* Automation Percentage Graph */}
      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Automation % by Property`} />
        </GraphCard.Header>
        <GraphCard.Content
          className={
            automationPercentGraphData.length === 0 && classes.graphContainer
          }
        >
          <AutomationPercentGraph data={automationPercentGraphData} />
        </GraphCard.Content>
      </GraphCard>

      {/* Average Satisfaction Score Graph */}
      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Average Satisfaction Score by Property`} />
        </GraphCard.Header>
        <GraphCard.Content
          className={
            avgSatisfactionScoreGraphData.length === 0 && classes.graphContainer
          }
        >
          <AverageSatisfactionScoreGraph data={avgSatisfactionScoreGraphData} />
        </GraphCard.Content>
      </GraphCard>

      {/* Problem Solved Percentage Graph */}
      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Problem Solved % by Property`} />
        </GraphCard.Header>
        <GraphCard.Content
          className={
            problemSolvedPercentGraphData.length === 0 && classes.graphContainer
          }
        >
          <ProblemSolvedGraph data={problemSolvedPercentGraphData} />
        </GraphCard.Content>
      </GraphCard>

      {/* Conditional Booking Engine Graphs - Only shown for chat channels except WhatsApp */}
      {AI_CHANNELS_CONFIG?.[selectedChannel]?.mode === CHANNEL_MODE.chat &&
        selectedChannel !== AI_SERVICES_CHANNELS.whatsApp && (
          <div className={classes.graphRow}>
            {/* Booking Engine Clicks Graph */}
            <GraphCard className={classes.halfWidth}>
              <GraphCard.Header>
                <GraphInsights title={t`Clicks by Property`} />
              </GraphCard.Header>
              <GraphCard.Content
                className={
                  bookingEngineClickGraphData.length === 0 &&
                  classes.graphContainer
                }
              >
                <BookingEngineClickGraph data={bookingEngineClickGraphData} />
              </GraphCard.Content>
            </GraphCard>

            {/* Booking Engine Value Graph */}
            <GraphCard className={classes.halfWidth}>
              <GraphCard.Header>
                <GraphInsights title={t`Booking value by Property`} />
              </GraphCard.Header>
              <GraphCard.Content
                className={
                  bookingEngineValueGraphData.length === 0 &&
                  classes.graphContainer
                }
              >
                <BookingEngineValueGraph data={bookingEngineValueGraphData} />
              </GraphCard.Content>
            </GraphCard>
          </div>
        )}
    </>
  );
}
