import { useMemo, useState } from 'react';
import { t } from '@lingui/macro';

import GraphInsights from '../GraphInsights';
import { MSG_CON, MSG_CON_LV } from '../constants';
import PieChart from '../../common/charts/PieChart';
import GraphCard from '../common/GraphCard';
import Tooltip from '../common/Tooltip';
import useSharedStyles from '../sharedStyles';
import { graphSettings } from '../common/graphSettings';
import NoGraphPlaceholder from '../common/NoGraphPlaceholder';

const tooltipComponent = (point = {}) => {
  const { id, value, color, data } = point.datum;
  return (
    <Tooltip color={color}>
      {id}: {value} ({data?.percent}%)
    </Tooltip>
  );
};

function GraphHeader({ typeGraph, setTypeGraph, overview }) {
  return (
    <GraphCard.Header
      showOptions
      optionList={MSG_CON_LV}
      onChange={setTypeGraph}
      selected={typeGraph}
    >
      <GraphInsights title={t`Connect to Human Requests`} />
      <GraphInsights
        title={t`Total Requests`}
        subTitle
        description={overview?.total || '-'}
      />
      <GraphInsights
        title={t`Average Response Time`}
        subTitle
        description={overview?.avgResponseTime || '-'}
      />
    </GraphCard.Header>
  );
}

export default function ConnectToHumanGraph({ graphInfo: data }) {
  const { classes } = useSharedStyles();
  const [typeGraph, setTypeGraph] = useState(MSG_CON.message);

  const { data: graphInfo, overview } = useMemo(() => {
    return typeGraph === MSG_CON.message ? data.messages : data.conversations;
  }, [typeGraph, data.messages, data.conversations]);

  // If no data display no data placeholder
  if (graphInfo.length === 0) {
    return (
      <>
        <GraphHeader
          typeGraph={typeGraph}
          setTypeGraph={setTypeGraph}
          overview={overview}
        />
        <GraphCard.Content className={classes.graphContainer}>
          <NoGraphPlaceholder />
        </GraphCard.Content>
      </>
    );
  }

  return (
    <>
      <GraphHeader
        typeGraph={typeGraph}
        setTypeGraph={setTypeGraph}
        overview={overview}
      />

      <GraphCard.Content className={classes.graphContainer}>
        <PieChart
          data={graphInfo}
          showLegend={false}
          margin={{
            ...graphSettings.margin.ConnectToHumanGraph,
          }}
          graphData={{
            tooltip: tooltipComponent,
          }}
        />
      </GraphCard.Content>
    </>
  );
}
