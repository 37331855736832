import { useMemo } from 'react';
import { t } from '@lingui/macro';

import { getGraphData } from './helpers';
import {
  AI_CHANNELS_CONFIG,
  AI_SERVICES_CHANNELS,
  CHANNEL_MODE,
} from '../ai-service-channels/constants';

import GraphCard from './common/GraphCard';
import GraphInsights from './GraphInsights';
import AutomationPercentGraph from './graphs/AutomationPercent';
import BookingEngineValue from './graphs/BookingEngineValue';
import ConnectToHumanGraph from './graphs/ConnectToHuman';
import CountGraph from './graphs/Count';
import ResponseTimeDistributionGraph from './graphs/ResponseTime';
import AverageSatisfactionScoreGraph from './graphs/AverageSatisfactionScore';
import SatisfactionScoreRangeGraph from './graphs/SatisfactionScoreRange';
import AvgResponseTimeByHourGraph from './graphs/AvgResponseTimeByHour';
import NoResponseCountByHourGraph from './graphs/NoResponseCountByHour';
import LanguageGraph from './graphs/Language';
import ProblemSolvedGraph from './graphs/ProblemSolved';
import TagsGraph from './graphs/Tags';
import BookingEngineClicks from './graphs/BookingEngineClicks';

import useSharedStyles from './sharedStyles';

/**
 * PropertyGraphs Component
 * Renders a collection of analytical graphs and charts for property-related data
 *
 * @param {string} selectedChannel - The currently selected communication channel
 * @param {string} selectedPeriod - The selected time period for data analysis
 * @param {Object} reportData - Raw data used to generate the graphs
 */
export default function PropertyGraphs({
  selectedChannel,
  selectedPeriod,
  reportData,
}) {
  const { classes } = useSharedStyles();

  /**
   * Processes raw report data into formatted data for individual graphs
   * Updates when period or report data changes
   */
  const {
    countGraphData,
    languageGraphData,
    problemSolvedGraphData,
    tagsGraphData,
    avgResponseTimeByHourData,
    noResponseCountByHourData,
    automationPercentData,
    allResponseTime,
    humanResponseTime,
    bookingEngineData,
    connectToHuman,
    avgSatisfactionScoreData,
    satisfactionScoreRangeData,
  } = useMemo(
    () => getGraphData({ period: selectedPeriod, data: reportData }),
    [selectedPeriod, reportData]
  );

  return (
    <>
      <div className={classes.graphRow}>
        {/* Line graph: CountGraph: Displays message count metrics */}
        <GraphCard className={classes.halfWidth}>
          <div className={classes.overflowGraph}>
            <CountGraph data={countGraphData} />
          </div>
        </GraphCard>

        {/* BarGraph: ResponseTimeDistributionGraph: Shows distribution of response times */}
        <GraphCard className={classes.halfWidth}>
          <ResponseTimeDistributionGraph
            data={{ allResponseTime, humanResponseTime }}
          />
        </GraphCard>
      </div>

      <div className={classes.graphRow}>
        {/* PieChart: AutomationPercentGraph: Compares AI vs human response rates */}
        <GraphCard className={classes.oneThirdWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`AI vs Human Response`} />
          </GraphCard.Header>
          <GraphCard.Content className={classes.graphContainer}>
            <AutomationPercentGraph graphInfo={automationPercentData} />
          </GraphCard.Content>
        </GraphCard>

        {/* Pie chart: LanguageGraph: Shows language distribution */}
        <GraphCard className={classes.oneThirdWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Language`} />
          </GraphCard.Header>
          <GraphCard.Content className={classes.graphContainer}>
            <LanguageGraph graphInfo={languageGraphData} />
          </GraphCard.Content>
        </GraphCard>

        {/* PieChart: ProblemSolvedGraph: Displays problem resolution metrics */}
        <GraphCard className={classes.oneThirdWidth}>
          <ProblemSolvedGraph graphInfo={problemSolvedGraphData} />
        </GraphCard>
      </div>

      {AI_CHANNELS_CONFIG?.[selectedChannel]?.mode === CHANNEL_MODE.chat &&
        selectedChannel !== AI_SERVICES_CHANNELS.whatsApp && (
          <div className={classes.graphRow}>
            {/* LineGraph: BookingEngineClicks: Visualizes booking engine click analytics */}
            <GraphCard className={classes.halfWidth}>
              <BookingEngineClicks
                data={{
                  ...bookingEngineData?.clicks,
                  axisBottomFormat: bookingEngineData.axisBottomFormat,
                }}
              />
            </GraphCard>

            {/* LineGraph: BookingEngineValue: Shows booking engine value metrics */}
            <GraphCard className={classes.halfWidth}>
              <BookingEngineValue
                data={{
                  ...bookingEngineData?.forwardedValues,
                  axisBottomFormat: bookingEngineData.axisBottomFormat,
                }}
              />
            </GraphCard>
          </div>
        )}

      <div className={classes.graphRow}>
        {/* PieChart: ConnectToHumanGraph: Displays human connection request metrics */}
        <GraphCard className={classes.halfWidth}>
          <ConnectToHumanGraph graphInfo={connectToHuman} />
        </GraphCard>

        {/* BarGraph: AverageSatisfactionScoreGraph: Shows average customer satisfaction */}
        <div className={classes.halfWidth}>
          <GraphCard>
            <GraphCard.Header>
              <GraphInsights title={t`Average Satisfaction Score`} />
            </GraphCard.Header>
            <GraphCard.Content>
              <AverageSatisfactionScoreGraph
                graphInfo={avgSatisfactionScoreData}
              />
            </GraphCard.Content>
          </GraphCard>
        </div>
      </div>

      {/* BarGraph: SatisfactionScoreRangeGraph: Displays range of satisfaction scores */}
      <GraphCard>
        <SatisfactionScoreRangeGraph graphInfo={satisfactionScoreRangeData} />
      </GraphCard>

      <div className={classes.graphRow}>
        {/* BarGraph: AvgResponseTimeByHourGraph: Shows average response times by min */}
        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Average Human Response Time (min)`} />
          </GraphCard.Header>
          <GraphCard.Content className={classes.graphContainer}>
            <AvgResponseTimeByHourGraph graphInfo={avgResponseTimeByHourData} />
          </GraphCard.Content>
        </GraphCard>

        {/* BarGraph: NoResponseCountByHourGraph: Displays messages without human response */}
        <GraphCard className={classes.halfWidth}>
          <GraphCard.Header>
            <GraphInsights title={t`Visitor Messages with no human response`} />
          </GraphCard.Header>
          <GraphCard.Content className={classes.graphContainer}>
            <NoResponseCountByHourGraph graphInfo={noResponseCountByHourData} />
          </GraphCard.Content>
        </GraphCard>
      </div>

      {/* BarGraph: TagsGraph: Shows distribution of conversation tags */}
      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Tags`} />
        </GraphCard.Header>
        <GraphCard.Content
          className={tagsGraphData.length === 0 && classes.graphContainer}
        >
          <TagsGraph graphInfo={tagsGraphData} />
        </GraphCard.Content>
      </GraphCard>
    </>
  );
}
