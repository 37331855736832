// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePie } from '@nivo/pie';
import { graphSettings } from '../../home/common/graphSettings';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function PieChart({
  data,
  tooltip,
  graphData = {},
  margin = { top: 40, right: 50, bottom: 80, left: -80 },
}) {
  return (
    <ResponsivePie
      data={data}
      margin={margin}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={[...graphSettings.defaultTheme]}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsStraightLength={5}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      enableArcLinkLabels={false}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      theme={{
        tooltip: {
          wrapper: {},
          container: {
            background: '#1f2937',
            color: '#ffffff',
            fontSize: 12,
          },
          basic: {},
          chip: {},
          table: {},
          tableCell: {},
          tableCellValue: {},
        },
      }}
      {...(tooltip && { tooltip })}
      {...graphData}
    />
  );
}
