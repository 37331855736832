/* eslint-disable import/prefer-default-export */
import { t } from '@lingui/macro';
import { CHANNELS_LABEL } from '../ai-service-channels/constants';
import { RESPONSE_TIME_BUCKET } from './constants';
import { getLanguageName } from '../../utils';
import {
  countByDaysKeys,
  countByHourKeys,
  getCountDataList,
  getLineGraphAxisBottomFormat,
} from './utils';

function convertToAmPmFormat(hour) {
  const period = hour < 12 ? 'am' : 'pm';
  const formattedHour = hour % 12 || 12;
  return `${formattedHour} ${period}`;
}

export function getChannelList(configuredChannels = []) {
  return configuredChannels.map((channel) => ({
    value: channel,
    label: CHANNELS_LABEL[channel],
  }));
}

// Function to get language data
function getLanguageData(data = {}) {
  if (data?.NA?.value === 0 && Object.keys(data).length === 1) return [];

  const languageData = Object.keys(data).map((code) => {
    const lng = code === 'NA' ? data[code].label : getLanguageName(code);
    return {
      id: lng,
      label: lng,
      value: data[code].value,
    };
  });

  return languageData.length ? languageData : [];
}

// Function to get automation percent data
function getAutomationPercentData(data = {}) {
  const hasNonZeroValue = Object.values(data).some((item) => item.value !== 0);
  if (!hasNonZeroValue) return [];

  const automationPercentData = Object.keys(data).map((code) => {
    const label = code === 'ai' ? 'AI' : 'Human';
    return {
      id: label,
      label,
      value: data[code].value,
      percent: data[code].percent,
    };
  });

  return automationPercentData.length ? automationPercentData : [];
}

// Function to get data related to problems solved
function getProblemSolvedData(data = {}) {
  const { ai = {}, human = {}, all = {} } = data;

  const mapData = (dataSet) => {
    // Only map if we have valid data
    return (
      Object.keys(dataSet)
        .filter((code) => dataSet[code].label !== 'No Data')
        .map((code) => ({
          id: dataSet[code].label,
          label: dataSet[code].label,
          value: dataSet[code].value,
        })) || []
    );
  };

  return {
    ai: mapData(ai),
    human: mapData(human),
    all: mapData(all),
  };
}

// Function to get combined response time distribution data
function getResponseTimeDistributionData(
  responseTimeDistribution = [],
  firstResponseTimeDistribution = []
) {
  const responseTimeBucketKeys = [
    ...new Set([
      ...Object.keys(responseTimeDistribution),
      ...Object.keys(firstResponseTimeDistribution),
    ]),
  ];

  const responseTimeData = responseTimeBucketKeys.map((bucket) => {
    const label = RESPONSE_TIME_BUCKET[bucket] || bucket;
    return {
      id: label,
      responseTimeDistribution: responseTimeDistribution?.[bucket]?.value,
      responseTimeDistributionData: {
        label: t`Response Time`,
        percent: responseTimeDistribution?.[bucket]?.percent,
      },
      firstResponseTimeDistribution:
        firstResponseTimeDistribution?.[bucket]?.value,
      firstResponseTimeDistributionData: {
        label: t`First Response Time`,
        percent: firstResponseTimeDistribution?.[bucket]?.percent,
      },
    };
  });

  return responseTimeData.length ? responseTimeData : [];
}

const getBarGraphDataViaKeyValue = (data = {}) => {
  const infoData = Object.keys(data).map((key) => ({
    id: key,
    [key]: data[key],
  }));

  return infoData.length ? infoData : [];
};

const getBarGraphDataViaLabelValue = (data = []) => {
  const infoData = data.map((info) => ({
    id: info.label,
    [info.label]: info.value,
  }));

  return infoData.length ? infoData : [];
};

const getBarGraphDataByHour = (data = {}) => {
  const infoData = Object.keys(data).map((key) => {
    const label = Number(key) >= 0 ? convertToAmPmFormat(Number(key)) : key;
    return {
      id: label,
      [label]: data[key],
    };
  });

  return infoData.length ? infoData : [];
};

function secondsToApproximateTime(sec) {
  let seconds = sec;
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let result = '';
  if (days > 0) result += `~${days} day${days > 1 ? 's' : ''}`;
  else if (hours > 0) result += `~${hours} hour${hours > 1 ? 's' : ''}`;
  else if (minutes > 0) result += `~${minutes} minute${minutes > 1 ? 's' : ''}`;
  else if (seconds > 0) result += `${seconds} second${seconds > 1 ? 's' : ''}`;

  return result;
}

export function getLineGraphData({ period, data }) {
  const createCountData = (key) =>
    getCountDataList({ period, data: data?.[key] });

  const messageCountData = createCountData('messagesCount');
  const conversationsCountData = createCountData('conversationsCount');
  const aiMessagesCountData = createCountData('aiMessagesCount');
  const aiConversationsCountData = createCountData('aiConversationsCount');

  const { axisBottomFormat } = getLineGraphAxisBottomFormat({
    period,
    graphDataList: messageCountData || [],
    hourCount: countByHourKeys(data?.messagesCount),
    dayCount: countByDaysKeys(data?.messagesCount),
  });

  return {
    messages: {
      overview: {
        count: {
          title: t`Messages`,
          totalTitleKey: t`messages`,
          total: data?.messagesCount?.total || 0,
          percentChange: data?.percentChange?.messages || 0,
        },
        aiCount: {
          title: t`AI Messages`,
          totalTitleKey: t`AI messages`,
          total: data?.aiMessagesCount?.total || 0,
          percentChange: data?.percentChange?.aiMessages || 0,
        },
      },
      data: [
        {
          id: 'AI Messages',
          color: '#346BFE',
          data: [...aiMessagesCountData],
        },
        {
          id: 'Messages',
          color: '#FD60A9',
          data: [...messageCountData],
        },
      ],
    },
    conversations: {
      overview: {
        count: {
          title: t`Conversations`,
          totalTitleKey: t`conversations`,
          total: data?.conversationsCount?.total || 0,
          percentChange: data?.percentChange?.conversations || 0,
        },
        aiCount: {
          title: t`AI Conversations`,
          totalTitleKey: t`AI conversations`,
          total: data?.aiConversationsCount?.total || 0,
          percentChange: data?.percentChange?.aiConversations || 0,
        },
      },
      data: [
        {
          id: 'AI Conversations',
          color: '#346BFE',
          data: [...aiConversationsCountData],
        },
        {
          id: 'Conversations',
          color: '#FD60A9',
          data: [...conversationsCountData],
        },
      ],
    },
    axisBottomFormat,
  };
}

function getClicksLineGraphData({ period, data }) {
  const createGraphData = (key) =>
    getCountDataList({ period, data: data?.[key] });

  const clicksData = createGraphData('bookingEngineClicks');
  const forwardedValueData = createGraphData('bookingEngineForwardedValue');

  const { axisBottomFormat } = getLineGraphAxisBottomFormat({
    period,
    graphDataList: clicksData || [],
    hourCount: countByHourKeys(data?.messagesCount),
    dayCount: countByDaysKeys(data?.messagesCount),
  });

  return {
    clicks: {
      overview: {
        title: t`Clicks`,
        totalTitleKey: t`clicks`,
        total: data?.bookingEngineClicks?.total || 0,
        percentChange: data?.percentChange?.bookingEngineClicks || 0,
      },
      data: [
        {
          id: 'Clicks',
          color: '#346BFE',
          data: [...clicksData],
        },
      ],
    },

    forwardedValues: {
      overview: {
        title: t`Booking Value`,
        totalTitleKey: t`values`,
        total: data?.bookingEngineForwardedValue?.total || 0,
        percentChange: data?.percentChange?.bookingEngineForwardedValue || 0,
      },
      data: [
        {
          id: 'Booking Value',
          color: '#FD60A9',
          data: [...forwardedValueData],
        },
      ],
    },

    axisBottomFormat,
  };
}

function getConnectToHumanData(data = {}) {
  // Destructure input data with default empty objects and response time
  const { messages = {}, conversations = {}, avgResponseTime } = data;

  // Helper function to calculate percentage with protection against division by zero
  const calculatePercent = (part = 0, total = 1) =>
    Math.round((part / (total || 1)) * 100);

  // Helper function to create data structure for messages and conversations
  const createDataObject = (dataObj) => {
    // Return empty arry if empty object
    if (Object.keys(dataObj).length === 0) return [];
    // Return empty array if no answered or unanswered items exist
    if (dataObj.answered === 0 && dataObj.unanswered === 0) return [];

    // Create array with two objects for Answered and Unanswered stats
    return [
      {
        id: 'Answered',
        label: t`Answered`,
        value: dataObj.answered, // Raw count of answered items
        percent: calculatePercent(dataObj.answered, dataObj.total), // Percentage of total
      },
      {
        id: 'Unanswered',
        label: t`Unanswered`,
        value: dataObj.unanswered, // Raw count of unanswered items
        percent: calculatePercent(dataObj.unanswered, dataObj.total), // Percentage of total
      },
    ];
  };

  // Return formatted data structure for both messages and conversations
  return {
    messages: {
      overview: {
        total: messages.total, // Total message count
        avgResponseTime: secondsToApproximateTime(avgResponseTime), // Formatted response time
      },
      data: createDataObject(messages), // Detailed message stats
    },
    conversations: {
      overview: {
        total: conversations.total, // Total conversation count
        avgResponseTime: secondsToApproximateTime(avgResponseTime), // Formatted response time
      },
      data: createDataObject(conversations), // Detailed conversation stats
    },
  };
}

// Formats satisfaction score range data for visualization
function getSatisfactionScoreRangeData(data = {}) {
  return {
    // Y-axis labels for the graph
    yLabels: data.yLabels || {},

    // Map raw number scores, adding yLabels to each score object
    number:
      data?.number?.map((score) => ({
        id: score.label,
        ...score.value,
        yLabels: data.yLabels || {},
      })) || [],

    // Map percentage scores, adding yLabels to each score object
    percent:
      data?.percent?.map((score) => ({
        id: score.label,
        ...score.value,
        yLabels: data.yLabels || {},
      })) || [],
  };
}

export function getGraphData({ period, data = {} }) {
  return {
    countGraphData: getLineGraphData({
      period,
      data,
    }),
    bookingEngineData: getClicksLineGraphData({
      period,
      data,
    }),
    languageGraphData: getLanguageData(data?.language),
    problemSolvedGraphData: getProblemSolvedData(data?.problemSolved),
    tagsGraphData: getBarGraphDataViaKeyValue(data?.tags),
    avgSatisfactionScoreData: getBarGraphDataViaLabelValue(
      data?.avgSatisfactionScore
    ),

    avgResponseTimeByHourData: getBarGraphDataByHour(
      data?.avgResponseTimeByHour
    ),
    noResponseCountByHourData: getBarGraphDataByHour(
      data?.noResponseCountByHour
    ),
    automationPercentData: getAutomationPercentData(data?.automationPercent),

    // All response time
    allResponseTime: {
      responseTimeDistribution: getResponseTimeDistributionData(
        data?.responseTimeDistribution,
        data?.firstResponseTimeDistribution
      ),
      averageResponseTime: data?.averageResponseTime
        ? secondsToApproximateTime(data?.averageResponseTime)
        : null,
      averageFirstResponseTime: data?.averageFirstResponseTime
        ? secondsToApproximateTime(data?.averageFirstResponseTime)
        : null,
    },

    // human response time
    humanResponseTime: {
      responseTimeDistribution: getResponseTimeDistributionData(
        data?.humanResponseTimeDistribution,
        data?.humanFirstResponseTimeDistribution
      ),
      averageResponseTime: data?.humanAverageResponseTime
        ? secondsToApproximateTime(data?.humanAverageResponseTime)
        : null,
      averageFirstResponseTime: data?.humanAverageFirstResponseTime
        ? secondsToApproximateTime(data?.humanAverageFirstResponseTime)
        : null,
    },
    // connect to human data
    connectToHuman: getConnectToHumanData(data?.connectToHumanData),

    // Satisfaction Score Range
    satisfactionScoreRangeData: getSatisfactionScoreRangeData(
      data?.satisfactionScoreRange
    ),
  };
}
