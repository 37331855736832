import NoGraphIcon from '@akin/ui-lib/svg/NoGraphIcon';
import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

export default function NoGraphPlaceholder() {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <NoGraphIcon />
    </div>
  );
}
