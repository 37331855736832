import { t } from '@lingui/macro';
import { AI_HUMAN_LABELS, HUMAN_ALL } from './constants';

export function getCsatScoreGraphData(data, filter) {
  if (!data?.total) {
    return [];
  }

  const metrics = {
    [HUMAN_ALL.ai]: {
      scoreCount: 'ai_score_count',
      requestCount: 'ai_request_count',
    },
    [HUMAN_ALL.human]: {
      scoreCount: 'human_score_count',
      requestCount: 'human_request_count',
    },
    default: {
      scoreCount: 'total_score_count',
      requestCount: 'total_request_count',
    },
  };

  const { scoreCount, requestCount } = metrics[filter] || metrics.default;
  const total = data.total || {};

  // Handle case where there are no requests
  if (!total[requestCount]) {
    return [];
  }

  const responsePercent = (total[scoreCount] / total[requestCount]) * 100 || 0;
  const noResponseCount = total[requestCount] - total[scoreCount];
  const noResponsePercent = (noResponseCount / total[requestCount]) * 100 || 0;

  return [
    {
      id: 'Response',
      label: t`Response`,
      value: total[scoreCount],
      hoverLabel: t`${total[scoreCount]} Response (${responsePercent.toFixed(
        1
      )}%)`,
    },
    {
      id: 'No Response',
      label: t`No Response`,
      value: noResponseCount,
      hoverLabel: t`${noResponseCount} No Response (${noResponsePercent.toFixed(
        1
      )}%)`,
    },
  ];
}

export function getCsatScoreTableData(data = {}) {
  const scores = data?.scores || [];
  return scores?.map((score) => ({
    timestamp: score?.created_at,
    score: score?.score,
    type: score?.type === 'HUMAN' ? AI_HUMAN_LABELS.human : score?.type,
    comment: score?.comment || '-',
    tags: score?.tags?.join(', ') || '-',
    externalConversationId: score?.external_conversation_id,
  }));
}

export function getProcessedCsatData({ data = {}, filter }) {
  return {
    csatScoreTableData: getCsatScoreTableData(data),
    csatResponseRateGraphData: getCsatScoreGraphData(data, filter),
  };
}
