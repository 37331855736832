import PieChart from '../../common/charts/PieChart';
import NoGraphPlaceholder from '../common/NoGraphPlaceholder';
import Tooltip from '../common/Tooltip';
import { graphSettings } from '../common/graphSettings';

const tooltipComponent = (point = {}) => {
  const { color, data } = point.datum;
  return <Tooltip color={color}>{data.hoverLabel}</Tooltip>;
};

export default function CsatResponseRateGraph({ graphInfo }) {
  // If no data display no data placeholder
  if (graphInfo.length === 0) {
    return <NoGraphPlaceholder />;
  }
  return (
    <PieChart
      data={graphInfo}
      showLegend={false}
      margin={graphSettings.margin.CsatResponseRateGraph}
      graphData={{
        tooltip: tooltipComponent,
        legends: [
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 40,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ],
        startAngle: -45,
      }}
    />
  );
}
