import { useMemo, useState } from 'react';
import { t } from '@lingui/macro';

import PieChart from '../../common/charts/PieChart';
import GraphInsights from '../GraphInsights';
import { HUMAN_AI_ALL_LV, HUMAN_ALL } from '../constants';
import GraphCard from '../common/GraphCard';
import useSharedStyles from '../sharedStyles';
import { graphSettings } from '../common/graphSettings';
import NoGraphPlaceholder from '../common/NoGraphPlaceholder';

function GraphHeader({ typeGraph, setTypeGraph }) {
  return (
    <GraphCard.Header
      showOptions
      optionList={HUMAN_AI_ALL_LV}
      onChange={setTypeGraph}
      selected={typeGraph}
    >
      <GraphInsights title={t`Was the problem solved`} />
    </GraphCard.Header>
  );
}

export default function ProblemSolvedGraph({ graphInfo: data }) {
  const { classes } = useSharedStyles();
  const [typeGraph, setTypeGraph] = useState(HUMAN_ALL.ai);

  const graphInfo = useMemo(() => {
    if (typeGraph === HUMAN_ALL.ai) return data.ai;
    if (typeGraph === HUMAN_ALL.all) return data.all;
    if (typeGraph === HUMAN_ALL.human) return data.human;
  }, [typeGraph, data.ai, data.all, data.human]);

  // If no data display no data placeholder
  if (graphInfo.length === 0) {
    return (
      <>
        <GraphHeader typeGraph={typeGraph} setTypeGraph={setTypeGraph} />
        <GraphCard.Content className={classes.graphContainer}>
          <NoGraphPlaceholder />
        </GraphCard.Content>
      </>
    );
  }

  return (
    <>
      <GraphHeader typeGraph={typeGraph} setTypeGraph={setTypeGraph} />
      <GraphCard.Content className={classes.graphContainer}>
        <PieChart
          data={graphInfo}
          showLegend={false}
          margin={{ ...graphSettings.margin.ProblemSolvedGraph }}
        />
      </GraphCard.Content>
    </>
  );
}
