import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  percentValue: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 32,
    padding: '2px 4px',
    backgroundColor: '#ddd',
    fontWeight: 600,
    fontSize: 10,
  },
}));

export default function PercentChangeComponent({ percentChange }) {
  const { classes } = useStyles();
  return (
    <div className={classes.percentValue}>
      {percentChange < 0 && <>&#8595; {percentChange * -1}%</>}
      {percentChange > 0 && <>&#8593; {percentChange}%</>}
    </div>
  );
}
