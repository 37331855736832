import { useMemo } from 'react';
import { createStyles } from '@mantine/core';

import { getChannelList } from '../helpers';

import PropertyGraphs from '../PropertyGraphs';
import GroupGraphs from '../GroupGraphs';
import FilterPanel from '../common/FilterPanel';

const useStyles = createStyles((theme) => ({
  filterPanelContainer: {
    padding: 10,
  },
  graphContainer: {
    padding: 10,
    paddingTop: 0,
    overflowY: 'auto',
    ...theme.other.customScrollbar,
  },
}));

// Home Overview page component that displays either Property or Group graphs
// with filtering options
export default function HomeOverviewPage({
  isGroup,
  configuredChannels,
  reportData,
  selectedOptions,
  setSelectedOptions,
  handleRefresh,
}) {
  const { classes } = useStyles();

  /**
   * Memoized channel list derived from configured channels
   * Prevents unnecessary recalculations on re-renders
   */
  const channelList = useMemo(
    () => getChannelList(configuredChannels),
    [configuredChannels]
  );

  /**
   * Updates the selected channel in filter options
   * @param {string} channelID - ID of the selected channel
   */
  const onSelectChannel = (channelID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      channel: channelID,
    }));
  };

  /**
   * Updates the selected time period in filter options
   * @param {string} periodID - ID of the selected period
   */
  const onSelectPeriod = (periodID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      period: periodID,
    }));
  };

  /**
   * Updates the selected filter in filter options
   * @param {string} filter - Selected filter configuration
   * Not enabled in overview
   */
  const onSelectFilter = (filter) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      filter,
    }));
  };

  return (
    <>
      <div className={classes.filterPanelContainer}>
        <FilterPanel
          onRefresh={() => handleRefresh()}
          channelList={channelList}
          disableFilter
          onSelectChannel={onSelectChannel}
          selectedChannel={selectedOptions.channel}
          onSelectPeriod={onSelectPeriod}
          selectedPeriod={selectedOptions.period}
          selectedFilter={selectedOptions.filter}
          setSelectedFilter={onSelectFilter}
        />
      </div>

      <div className={classes.graphContainer}>
        {/* Display graphs for group space */}
        {isGroup ? (
          <GroupGraphs
            selectedChannel={selectedOptions.channel}
            selectedPeriod={selectedOptions.period}
            reportData={reportData}
          />
        ) : (
          // Display graphs property space
          <PropertyGraphs
            selectedChannel={selectedOptions.channel}
            selectedPeriod={selectedOptions.period}
            reportData={reportData}
          />
        )}
      </div>
    </>
  );
}
