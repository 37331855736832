// Import necessary hooks and components
import { useEffect, useMemo, useState } from 'react';
import { createStyles } from '@mantine/core';
import { LoadingOverlay } from '@akin/ui-lib';
import ICONS from '@akin/core-lib/icons';

import useSpaceSettings from '../../hooks/useSpaceSettings';

import HomeOverviewPage from './tabs/Overview';
import HomeCSATPage from './tabs/CSAT';
import { LIST_TYPE, TIME_PERIOD } from '../common/time-range-filter/constants';
import { processReportsParams } from '../common/time-range-filter/utils';
import { getCSATReport, getHomePageReport } from '../../services/reports';
import { showError } from '../../lib/notifications';
import MainContainer from '../layouts/components/MainContainer';

// Define styles for the component
const useStyles = createStyles(() => ({
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

const initialFilterOptions = {
  period: {
    ...TIME_PERIOD.LAST_MONTH,
    id: LIST_TYPE.default,
  },
  filter: undefined,
  channel: undefined,
};

// Define the available tabs
const TABS = {
  overview: { id: 'overview', title: 'Overview', icon: ICONS.overviewReport },
  csat: { id: 'csat', title: 'CSAT', icon: ICONS.csatReport },
  // optii: { id: 'csat', title: 'Optii', icon: ICONS.optiiReport },
};

// Function to get the list of tabs
function getTabList() {
  const tabList = { ...TABS };
  return { ...tabList };
}

// Main component for the Home Page
export default function HomePage() {
  const { classes } = useStyles();
  const {
    currentSpace,
    spaceSettings: {
      currentSpaceId,
      isSpaceLoading,
      configuredReportChannels,
      configuredCsatChannels,
      isGroup,
    },
  } = useSpaceSettings();
  const tabList = useMemo(() => getTabList(isGroup), [isGroup]);
  const [activeTab, setActiveTab] = useState(TABS.overview.id);
  const [isLoading, setIsLoading] = useState(false);

  // State for CSAT options
  const [selectedCsatOptions, setSelectedCsatOptions] = useState({
    ...initialFilterOptions,
  });

  // State for Overview options
  const [selectedOverviewOptions, setSelectedOverviewOptions] = useState({
    ...initialFilterOptions,
  });

  const [csatReportData, setCsatReportData] = useState(null);
  const [overviewReportData, setOverviewReportData] = useState(null);

  // Function to fetch CSAT report data
  const fetchCsatReport = async (options = {}) => {
    if (!options.channel) return;
    setIsLoading(true);
    try {
      const payload = processReportsParams(
        { ...options.period, timezone: currentSpace.timezone },
        'csat'
      );
      const response = await getCSATReport({
        ...payload,
        csatType: options.filter,
        channel: options.channel || undefined,
        pagination: {
          currentPage: 1,
        },
      });
      setCsatReportData(response);
    } catch (error) {
      showError(error.message);
    }
    setIsLoading(false);
  };

  // Function to fetch Overview report data
  const fetchOverviewReport = async (options = {}) => {
    if (!options.channel) return;
    setIsLoading(true);
    try {
      const payload = processReportsParams(options.period);
      const response = await getHomePageReport({
        ...payload,
        channel: options.channel || undefined,
      });
      setOverviewReportData(response);
    } catch (error) {
      showError(error.message);
    }
    setIsLoading(false);
  };

  // Function to handle refreshing of data based on active tab
  const handleRefresh = () => {
    if (activeTab === TABS.overview.id) {
      fetchOverviewReport(selectedOverviewOptions);
    } else if (activeTab === TABS.csat.id) {
      fetchCsatReport(selectedCsatOptions);
    }
  };

  useEffect(() => {
    function init() {
      // Initialize states and fetch overview report data
      setOverviewReportData(null);
      setCsatReportData(null);

      if (!isSpaceLoading) {
        setActiveTab(TABS.overview.id);
        setSelectedOverviewOptions({
          ...initialFilterOptions,
          channel: configuredReportChannels?.[0],
        });
        setSelectedCsatOptions({
          ...initialFilterOptions,
          channel: configuredCsatChannels?.[0],
        });
      }
    }
    init();
  }, [currentSpaceId, isSpaceLoading]);

  useEffect(() => {
    // Fetch CSAT report data when the active tab is CSAT and data is not present
    if (activeTab === TABS.csat.id && !csatReportData) {
      fetchCsatReport(selectedCsatOptions);
    }
  }, [activeTab, csatReportData]);

  useEffect(() => {
    // Fetch CSAT report data when CSAT options change
    if (activeTab === TABS.csat.id) {
      fetchCsatReport(selectedCsatOptions);
    }
  }, [selectedCsatOptions]);

  useEffect(() => {
    if (isSpaceLoading) return; // Skip if initial fetch is not complete
    // Fetch overview report data when the active tab is Overview and data is not present
    if (activeTab === TABS.overview.id && !overviewReportData) {
      fetchOverviewReport(selectedOverviewOptions);
    }
  }, [activeTab, overviewReportData]);

  useEffect(() => {
    if (isSpaceLoading) return; // Skip if initial fetch is not complete
    // Fetch overview report data when Overview options change
    if (activeTab === TABS.overview.id) {
      fetchOverviewReport(selectedOverviewOptions);
    }
  }, [selectedOverviewOptions]);

  return (
    <MainContainer
      pageTitle="Home"
      tabList={tabList}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      <LoadingOverlay visible={isLoading} overlayOpacity={0.3} />

      <div className={classes.tabContainer}>
        {
          {
            // show all overview graphs
            [TABS.overview.id]: (
              <HomeOverviewPage
                isGroup={isGroup}
                configuredChannels={configuredReportChannels}
                reportData={overviewReportData}
                selectedOptions={selectedOverviewOptions}
                setSelectedOptions={setSelectedOverviewOptions}
                handleRefresh={() => handleRefresh()}
              />
            ),
            // show csat related graphs and table
            [TABS.csat.id]: (
              <HomeCSATPage
                isGroup={isGroup}
                configuredChannels={configuredCsatChannels}
                reportData={csatReportData}
                selectedOptions={selectedCsatOptions}
                setSelectedOptions={setSelectedCsatOptions}
                handleRefresh={() => handleRefresh()}
              />
            ),
          }[activeTab]
        }
      </div>
    </MainContainer>
  );
}
