import { Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    gap: 10,
    '&.center': {
      justifyContent: 'center',
    },
    '&.noGap': {
      gap: 0,
    },
  },
}));

export default function GraphInsights({
  title,
  subTitle = false,
  description,
  center = false,
  noGap = false,
}) {
  const { cx, classes } = useStyles();

  return (
    <div className={cx(classes.container, { center, noGap })}>
      {title && (
        <Text weight={subTitle ? 400 : 600} size="xs">
          {title}:
        </Text>
      )}
      <Text size="xs">{description}</Text>
    </div>
  );
}
