import { createStyles } from '@mantine/core';
import { IconButton, Button, TextInput, Popover } from '@akin/ui-lib';
import { Trans, t } from '@lingui/macro';
import { useCallback, useMemo, useState } from 'react';
import ArrowIcon from '@akin/ui-lib/svg/ArrowIcon';
import { getRangeButtonText, getTimeList } from './utils';
import RangeSelectorForm from './RangeSelectorForm';
import { LIST_TYPE } from './constants';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
  },
  dropdown: {
    width: 290,
  },
  dropdownLabel: {
    padding: '8px 12px',
    fontSize: 10,
    fontWeight: 600,
    borderBottom: '1px solid #f2f3f5',
  },
  dropdownItem: {
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: 14,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#f2f3f5',
    },
    '&.selected': {
      backgroundColor: '#f2f3f5',
    },
  },

  contentWrap: {
    position: 'relative',
    width: '100%',
    minWidth: 0,
    display: 'flex',
    gap: 8,
    justifyContent: 'space-between',
    padding: '8px 0px',
  },
  labelWrap: {
    paddingRight: 8,
    width: '100%',
    minWidth: 0,
  },
  label: {
    marginBottom: 0,
    lineHeight: 1.4,
    display: 'block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateTime: {
    fontSize: 12,
    lineHeight: 1.2,
    marginBottom: 0,
    color: 'rgb(101, 89, 197)',
  },
  svgIcon: {
    height: 12,
    width: 12,
    fill: 'currentColor',
    transform: 'rotate(90deg)',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '1.4em',
    gap: 8,
    marginRight: 4,
  },
  Button: {
    border: 'none',
    height: 30,
    fontWeight: 500,
    backgroundColor: theme.white,
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
    },
  },
}));

function ListItemForCalendar({ selected, handleToggleCalendar }) {
  const { cx, classes } = useStyles();
  return (
    <div
      className={cx(classes.dropdownItem, {
        selected: selected.id === LIST_TYPE.calendar,
      })}
      onClick={handleToggleCalendar}
      aria-hidden
    >
      <div className={classes.contentWrap}>
        <div className={classes.labelWrap}>
          <div className={classes.label}>
            <span>
              <div>
                <Trans>Custom date range</Trans>
              </div>
            </span>
          </div>
          {selected.id === LIST_TYPE.calendar && (
            <div className={classes.dateTime}>
              <span>
                <time>{getRangeButtonText(selected)}</time>
              </span>
            </div>
          )}
        </div>
        <div className={classes.actionContainer}>
          <IconButton>
            <ArrowIcon className={classes.svgIcon} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default function TimeRangeFilter({ disabled, onClick, selected }) {
  const { cx, classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [isCalendarOn, setIsCalendarOn] = useState(false);
  const [keyword, setKeyword] = useState('');
  const timeList = useMemo(
    () => getTimeList(keyword, selected),
    [keyword, selected]
  );

  // ----------- state updates
  const handleToggleDropdown = () => {
    setOpened(!opened);
  };

  const handleToggleCalendar = () => {
    setIsCalendarOn(true);
  };

  const handleBackFromCalendar = () => {
    setIsCalendarOn(false);
  };
  // -----------

  // This function handles the selection of a default time item and
  // triggers the onClick event with the selected item as the payload.
  const handleSelectDefaultTimeItem = useCallback(
    (item) => {
      setOpened(false);
      onClick({
        ...item,
        id: item.timeValue ? LIST_TYPE.search : LIST_TYPE.default,
      });
      setKeyword('');
    },
    [onClick]
  );

  // This function handles the selection of a range and
  //  triggers the onClick event with the range payload, including the value and id.
  const handleSelectRange = useCallback(
    (payload) => {
      setOpened(false);
      onClick({ value: payload, id: LIST_TYPE.calendar });
    },
    [onClick]
  );

  const onSearchHandler = (e) => {
    const searchedValue = e.target.value;
    setKeyword(searchedValue);
  };

  const onChangePopover = (openValue) => {
    if (!openValue) {
      setKeyword('');
    }
    setOpened(openValue);
    setIsCalendarOn(false);
  };

  return (
    <div className={classes.container}>
      <Popover opened={opened} onChange={onChangePopover}>
        <Popover.Target>
          <Button
            onClick={handleToggleDropdown}
            radius="sm"
            variant="outline"
            className={classes.Button}
            disabled={disabled}
          >
            {getRangeButtonText(selected)}
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          <div className={classes.dropdown}>
            {!isCalendarOn && (
              <>
                <TextInput
                  placeholder={t`Change range: 2h, 2d ,2w`}
                  id="search-range"
                  name="search-range"
                  onChange={onSearchHandler}
                  required
                  mb={10}
                />
                {timeList.map((item) => (
                  <div
                    key={item.value}
                    className={cx(classes.dropdownItem, {
                      selected: selected.value === item.value,
                    })}
                    onClick={() => handleSelectDefaultTimeItem(item)}
                    aria-hidden
                  >
                    {item.label}
                  </div>
                ))}
                {!keyword && (
                  <ListItemForCalendar
                    selected={selected}
                    handleToggleCalendar={handleToggleCalendar}
                  />
                )}
              </>
            )}
            {isCalendarOn ? (
              <RangeSelectorForm
                onBackHandler={handleBackFromCalendar}
                onApplyHandler={handleSelectRange}
                rangeValues={null}
              />
            ) : null}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}
