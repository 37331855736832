import React from 'react';
import BarGraph from '../../common/charts/BarGraph';
import BarGraphTickTextRender from '../common/BarGraphTickTextRender';
import Tooltip from '../common/Tooltip';
import { graphSettings } from '../common/graphSettings';

const tooltipComponent = ({ id, value, color }) => (
  <Tooltip color={color}>
    {id}: {value}
  </Tooltip>
);

export default function TagsGraph({ graphInfo }) {
  const isVerticalTick = graphInfo.length > 6;

  const graphProps = {
    axisLeft: {
      format: (e) => Math.floor(e) === e && e,
    },
    ...(!isVerticalTick && {
      axisBottom: {
        tickSize: 0,
        tickPadding: 25,
        tickRotation: 0,
        renderTick: (props) => (
          <BarGraphTickTextRender {...props} maxLineLength={6} />
        ),
      },
      margin: {
        ...graphSettings.margin.TagsHorizontalGraph,
        ...(graphInfo.length === 0 && { bottom: 10 }),
      },
    }),
    ...(isVerticalTick && {
      margin: {
        ...graphSettings.margin.TagsGraph,
      },
    }),
  };

  return (
    <BarGraph
      data={graphInfo}
      keys={graphInfo.map((info) => info.id)}
      indexBy="id"
      tooltip={tooltipComponent}
      xAxisTickRotation={90}
      graphProps={graphProps}
    />
  );
}
