import { useMemo, useState } from 'react';
import { createStyles } from '@mantine/core';

import LineGraph from '../../common/charts/LineGraph';
import GraphInsights from '../GraphInsights';
import { MSG_CON, MSG_CON_LV } from '../constants';
import GraphCard from '../common/GraphCard';
import PercentChangeComponent from '../common/PercentChangeComponent';
import { graphSettings } from '../common/graphSettings';

const useStyles = createStyles((theme) => ({
  countDescription: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [theme.fn.smallerThan('xs')]: {
      gap: 0,
      // flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
  },
}));

function CountDescription({ data }) {
  const { classes } = useStyles();
  return (
    <div className={classes.countDescription}>
      {data?.total}
      {data?.percentChange ? (
        <PercentChangeComponent percentChange={data?.percentChange} />
      ) : null}
    </div>
  );
}

export default function CountGraph({ data }) {
  const [typeGraph, setTypeGraph] = useState(MSG_CON.message);

  const { axisBottomFormat } = data;

  const { data: graphInfo, overview } = useMemo(() => {
    return typeGraph === MSG_CON.message ? data.messages : data.conversations;
  }, [typeGraph, data.messages, data.conversations]);

  return (
    <>
      <GraphCard.Header
        showOptions
        optionList={MSG_CON_LV}
        onChange={setTypeGraph}
        selected={typeGraph}
      >
        <GraphInsights
          title={overview?.count?.title}
          description={
            <CountDescription
              totalTitleKey={overview?.count?.totalTitleKey}
              data={overview?.count}
            />
          }
        />
        <GraphInsights
          title={overview?.aiCount?.title}
          description={
            <CountDescription
              totalTitleKey={overview?.aiCount?.totalTitleKey}
              data={overview?.aiCount}
            />
          }
        />
      </GraphCard.Header>

      <GraphCard.Content>
        <LineGraph
          data={graphInfo}
          axisBottomFormat={axisBottomFormat}
          isMobileScreen
          colors={graphSettings.dualColorPalette}
          indexBy="date"
          graphData={{
            margin: graphSettings.margin.CountGraph,
          }}
        />
      </GraphCard.Content>
    </>
  );
}
