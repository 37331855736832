import { createStyles } from '@mantine/core';
import { t } from '@lingui/macro';

import LineGraph from '../../common/charts/LineGraph';
import GraphInsights from '../GraphInsights';
import GraphCard from '../common/GraphCard';
import PercentChangeComponent from '../common/PercentChangeComponent';
import { graphSettings } from '../common/graphSettings';

const useStyles = createStyles((theme) => ({
  countDescription: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [theme.fn.smallerThan('xs')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
  },
}));

function CountDescription({ description, percentChange }) {
  const { classes } = useStyles();
  return (
    <div className={classes.countDescription}>
      {description}
      {percentChange ? (
        <div className={classes.percentValue}>
          <PercentChangeComponent percentChange={percentChange} />
        </div>
      ) : null}
    </div>
  );
}

export default function BookingEngineClicks({ data }) {
  const { data: graphInfo, overview, axisBottomFormat } = data;

  return (
    <>
      <GraphCard.Header>
        <GraphInsights
          title={overview?.title}
          description={
            <CountDescription
              description={t`Total number of clicks to booking engine: ${overview.total}`}
              percentChange={overview?.percentChange}
            />
          }
        />
      </GraphCard.Header>

      <GraphCard.Content>
        <LineGraph
          data={graphInfo}
          axisBottomFormat={axisBottomFormat}
          axisBottomRotation={{
            tickRotation: 90,
            legendOffset: -80,
          }}
          isMobileScreen
          colors={graphSettings.monochromeColorPalette}
          indexBy="date"
          graphData={{
            margin: graphSettings.margin.BookingEngineClicks,
          }}
        />
      </GraphCard.Content>
    </>
  );
}
