import { formatDate, DATE_FORMATS } from '@akin/core-lib/utils/dateTime';
import { TIME_PERIOD } from '../common/time-range-filter/constants';

/**
 * Creates an array of hourly data points with formatted date-time labels
 * @param {Object} byDate - Object containing date-indexed data with nested hourly counts
 * @returns {Array<Object>} Array of data points, each with:
 *   - x: formatted string like "Mar 15 - 09:00"
 *   - y: count/value for that hour
 */
function createHourChartData(byDate) {
  const list = [];

  Object.keys(byDate).forEach((date) => {
    // Clone the byHour object to avoid mutating the original data
    const byHour = { ...byDate[date].byHour };

    // For each hour in the day
    Object.keys(byHour).forEach((key) => {
      list.push({
        // Format x-axis label as "MMM dd - HH:00" (e.g., "Mar 15 - 09:00")
        // padStart ensures hours are always 2 digits (e.g., "09" instead of "9")
        x: `${formatDate(date, 'MMM dd', 'yyyy-MM-dd')} - ${key.padStart(
          2,
          '0'
        )}:00`,
        // y value is the count/value for this specific hour
        y: byHour[key],
      });
    });
  });

  return list;
}

function create24HoursChartData(byDate) {
  const list = [];

  Object.keys(byDate).forEach((date) => {
    const byHour = { ...byDate[date].byHour };
    Object.keys(byHour).forEach((key) => {
      list.push({
        x: `${key.padStart(2, '0')}:00`,
        y: byHour[key],
      });
    });
  });

  return list;
}

function createDateChartData(data = {}) {
  const chartData = [];
  Object.keys(data).forEach((date) => {
    chartData.push({
      x: formatDate(date, DATE_FORMATS.LONG4, 'yyyy-MM-dd'),
      y: data[date].total,
    });
  });

  return chartData;
}

export function countByHourKeys(data = {}) {
  const { byDate = {} } = data;
  const result = {};
  if (byDate) {
    Object.keys(byDate).forEach((date) => {
      result[date] = Object.keys(byDate[date]?.byHour).length;
    });
    const total = Object.values(result).reduce((acc, curr) => acc + curr, 0);
    return total;
  }
}

export function countByDaysKeys(data = {}) {
  const { byDate = {} } = data;
  if (byDate) {
    return Object.keys(byDate).length;
  }
}

/**
 * Processes a list of graph data points to format hour labels, handling date transitions
 * @param {Array} graphDataList - Array of data points with x (datetime string) and y values
 * @returns {Array} Processed array with additional 'recent' property for axis labels
 */
function processHoursFormat(graphDataList = []) {
  const result = [];
  let currentDate = ''; // Tracks the current date to detect date changes
  const graphDataListLength = graphDataList.length;

  for (let i = 0; i < graphDataListLength; i += 1) {
    const theDate = graphDataList[i]?.x;
    if (theDate) {
      // Split datetime string into date and time components (e.g., "Mar 15 - 09:00")
      const [date, time] = theDate.split(' - ');

      if (date !== currentDate) {
        // When date changes, show full datetime for better context
        currentDate = date;
        result.push({ ...graphDataList[i], recent: graphDataList[i]?.x });
      } else {
        // For same date entries, only show time portion
        result.push({ ...graphDataList[i], recent: time });
      }
    }
    // Handle null/undefined x values by setting recent to empty string
    if (!theDate) result.push({ ...graphDataList[i], recent: '' });
  }

  return result;
}

/**
 * Determines the format for the bottom axis labels of a line graph based on various conditions
 * @param {Object} params - The parameters object
 * @param {Object} params.period - Time period object containing value property
 * @param {Array} params.graphDataList - Array of data points for the graph
 * @param {number} params.hourCount - Total number of hours in the data
 * @param {number} params.dayCount - Total number of days in the data
 * @returns {Object} Object containing axisBottomFormat function
 */
export function getLineGraphAxisBottomFormat({
  period,
  graphDataList = [],
  hourCount,
  dayCount,
}) {
  const { value: periodId } = period;
  let valuesToShow;
  let axisBottomFormat;

  // For today's view, 24 hours or less data, or last month view
  // Show every third label (i % 3 === 0)
  if (
    periodId === TIME_PERIOD.TODAY.value ||
    hourCount <= 24 ||
    periodId === TIME_PERIOD.LAST_MONTH.value
  ) {
    valuesToShow = graphDataList.map((v, i) => (i % 3 !== 0 ? '' : v));
    axisBottomFormat = (v) =>
      valuesToShow.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }

  // For last week view, show all labels
  if (periodId === TIME_PERIOD.LAST_WEEK.value) {
    axisBottomFormat = (v) =>
      graphDataList.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }

  // For 25-48 hours of data
  // Show every third label with special hours formatting
  if (hourCount <= 48) {
    valuesToShow = processHoursFormat(
      graphDataList.map((v, i) => (i % 3 !== 0 ? '' : v))
    );

    axisBottomFormat = (v) => {
      const data = valuesToShow.find((vts) => vts.x === v);
      return data?.recent || '';
    };

    return { axisBottomFormat };
  }

  // For 2 weeks or less of daily data, show all labels
  if (dayCount <= 14) {
    axisBottomFormat = (v) =>
      graphDataList.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }

  // For up to 3 months of data (92 days)
  // Show approximately 10 labels evenly distributed
  if (dayCount <= 92) {
    const divisor = Math.ceil(dayCount / 10);
    valuesToShow = graphDataList.map((v, i) => (i % divisor !== 0 ? '' : v));
    axisBottomFormat = (v) =>
      valuesToShow.find((vts) => vts.x === v) ? v : '';

    return { axisBottomFormat };
  }

  // Default case: return empty format function
  return { axisBottomFormat };
}

export function getCountDataList({ data = {} }) {
  const byDate = { ...data?.byDate };

  const hourCount = countByHourKeys(data);

  let graphDataList = [];

  if (hourCount <= 24) {
    return create24HoursChartData(byDate);
  }

  if (hourCount < 48) {
    return createHourChartData(byDate);
  }

  graphDataList = createDateChartData(byDate);

  return graphDataList;
}
