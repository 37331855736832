import { t } from '@lingui/macro';
import PieChart from '../../common/charts/PieChart';
import Tooltip from '../common/Tooltip';
import { graphSettings } from '../common/graphSettings';
import NoGraphPlaceholder from '../common/NoGraphPlaceholder';

const tooltipComponent = (point = {}) => {
  const { id, value, color, data } = point.datum;
  return (
    <Tooltip color={color}>
      {id}: {value} {t`Messages`} ({data?.percent}%)
    </Tooltip>
  );
};

export default function AutomationPercentGraph({ graphInfo }) {
  // If no data display no data placeholder
  if (graphInfo.length === 0) {
    return <NoGraphPlaceholder />;
  }

  return (
    <PieChart
      data={graphInfo}
      showLegend={false}
      margin={{ ...graphSettings.margin.AutomationPercentGraph }}
      graphData={{
        tooltip: tooltipComponent,
      }}
    />
  );
}
