import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { createStyles } from '@mantine/core';

import { getChannelList } from '../helpers';
import FilterPanel from '../common/FilterPanel';
import GraphCard from '../common/GraphCard';
import GraphInsights from '../GraphInsights';
import CSATScoresTable from '../tabular-reports/CSATScoresTable';
import CsatResponseRateGraph from '../graphs/CsatResponseRate';
import useSharedStyles from '../sharedStyles';
import { getProcessedCsatData } from '../csat-helpers';

const useStyles = createStyles((theme) => ({
  filterPanelContainer: {
    padding: 10,
  },
  graphContainer: {
    padding: 10,
    paddingTop: 0,
    overflowY: 'auto',
    ...theme.other.customScrollbar,
  },
  headerRow: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
}));

const INSIGHTS_CONFIG = [
  {
    id: 'total',
    title: t`Average Score`,
    value: 'total_average',
    showWhen: (filter) => !filter,
  },
  {
    id: 'ai',
    title: t`Average AI Score`,
    value: 'ai_average',
    showWhen: (filter) => filter === 'ai' || !filter,
  },
  {
    id: 'human',
    title: t`Average Human Score`,
    value: 'human_average',
    showWhen: (filter) => filter === 'human' || !filter,
  },
];

export default function HomeCSATPage({
  configuredChannels,
  reportData,
  selectedOptions,
  setSelectedOptions,
  handleRefresh,
}) {
  const { classes } = useSharedStyles();
  const { classes: pageClasses } = useStyles();

  const channelList = useMemo(
    () => getChannelList(configuredChannels),
    [configuredChannels]
  );

  const { csatScoreTableData, csatResponseRateGraphData } = useMemo(
    () =>
      getProcessedCsatData({
        data: reportData,
        filter: selectedOptions.filter,
      }),
    [reportData, selectedOptions.filter]
  );

  const onSelectChannel = (channelID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      channel: channelID,
    }));
  };

  const onSelectPeriod = (periodID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      period: periodID,
    }));
  };

  const onSelectFilter = (filter) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      filter,
    }));
  };

  return (
    <>
      <div className={pageClasses.filterPanelContainer}>
        <FilterPanel
          onRefresh={() => handleRefresh()}
          channelList={channelList}
          onSelectChannel={onSelectChannel}
          selectedChannel={selectedOptions.channel}
          onSelectPeriod={onSelectPeriod}
          selectedPeriod={selectedOptions.period}
          selectedFilter={selectedOptions.filter}
          setSelectedFilter={onSelectFilter}
        />
      </div>

      <div className={pageClasses.graphContainer}>
        {selectedOptions.channel && (
          <div className={classes.graphRow}>
            <GraphCard className={classes.twoThirdWidth}>
              <GraphCard.Header>
                <div className={pageClasses.headerRow}>
                  <GraphInsights title={t`CSAT Scores`} noGap />
                  {INSIGHTS_CONFIG.map(
                    ({ id, title, value, showWhen }) =>
                      showWhen(selectedOptions.filter) && (
                        <GraphInsights
                          key={id}
                          subTitle
                          description={
                            <>
                              [{title}:{' '}
                              <b>{`${reportData?.total?.[value]}`} </b>]
                            </>
                          }
                        />
                      )
                  )}
                </div>
              </GraphCard.Header>
              <div className={classes.overflowGraph}>
                <CSATScoresTable data={csatScoreTableData} />
              </div>
            </GraphCard>
            <div className={classes.oneThirdWidth}>
              <GraphCard>
                <GraphCard.Header>
                  <GraphInsights title={t`CSAT response rate`} />
                </GraphCard.Header>
                <GraphCard.Content className={classes.graphContainer}>
                  <CsatResponseRateGraph
                    graphInfo={csatResponseRateGraphData}
                  />
                </GraphCard.Content>
              </GraphCard>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
